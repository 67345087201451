import React from 'react';
import {FaLinkedin} from 'react-icons/fa'
import {motion} from 'framer-motion';
import { fadeIn } from '../variant';

const Contact = () => {
    return  (
        <section id='contact' className=' mx-auto pr-10 pl-10 flex-col lg:gap-y-[25px] gap-y-[20px]' >

        <motion.div variants={fadeIn('right',0.3)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.7}} 
                className='container lg:mr-[380px] lg:w-[452px] md:mr-[300px] md:w-[360px] w-[150px] p-[10px] text-center blueBackGroundGrandRtoL rounded-[20px]'>
                <h2 className='text-white font-bold lg:text-[28px] md:text-[25px] text-[20px]'> Seems </h2><span className='w-2'/>
        </motion.div>
        <motion.div variants={fadeIn('left',0.3)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.7}} 
                className='container lg:ml-[380px]  lg:w-[452px] md:ml-[300px] md:w-[360px] w-[180px]  p-[10px] text-center blueBackGroundGrandLtoR rounded-[20px]'>
                <h2 className='text-white font-bold lg:text-[30px] md:text-[25px] text-[20px]'> like </h2><span className='w-2'/>
        </motion.div>
        <motion.div variants={fadeIn('right',0.3)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.7}} 
                className='container lg:mr-[300px]  lg:w-[452px] md:mr-[240px] md:w-[360px] w-[210px]  p-[10px] text-center blueBackGroundGrandRtoL rounded-[20px]'>
                <h2 className='text-white font-bold lg:text-[30px] md:text-[25px] text-[20px]'> you </h2><span className='w-2'/>
        </motion.div>
        <motion.div variants={fadeIn('left',0.3)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.7}} 
                className='container lg:ml-[300px]  lg:w-[452px] md:ml-[240px] md:w-[360px] w-[240px] p-[10px] text-center blueBackGroundGrandLtoR rounded-[20px]'>
                <h2 className='text-white font-bold lg:text-[30px] md:text-[25px] text-[20px]'> are </h2><span className='w-2'/>
        </motion.div>
        <motion.div variants={fadeIn('right',0.3)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.7}} 
                className='container lg:mr-[0px] lg:w-[452px] md:mr-[0px] md:w-[360px] w-[270px] p-[10px] text-center blueBackGroundGrandRtoL rounded-[20px]'>
                <h2 className='text-white font-bold lg:text-[30px] md:text-[25px] text-[20px]'> interested! </h2><span className='w-2'/>
        </motion.div>   


            <motion.div variants={fadeIn('up',0.3)} initial='hidden'
                                whileInView={'show'} viewport={{once: false, amount: 0.7}} 
                                className='container mx-auto lg:w-[452px] md:w-[360px] w-[300px] p-8 blueBackGroundGrand
                                rounded-[20px]'>
                <div className='flex'>
                    <h2 className='text-white font-bold lg:text-[40px] text-[30px]'> Let's </h2><span className='w-2'/>
                    <h2 className='text-white font-bold lg:text-[40px] text-[30px]'>
                    connect!
                    </h2>
                </div>
                <div className='flex-col text-white lg:text-[16px] md:text-[15px] text-[14px]'>
                    <p>If you want to contact me you can use LinkedIn.</p>
                    <div className='lg:p-2 p-[5.45px] lg:mt-2 mt-1 border-2  bg-transparent hover:bg-white 
                    hover:text-blue-400 lg:h-[45px] lg:w-[45px] h-[35px] w-[35px] rounded-[10px]'>
                            <a href="https://www.linkedin.com/in/jaroslav-romashchuk/">
                                <FaLinkedin className=' lg:w-[25px] lg:h-[25px] w-[20px] h-[20px]'/>
                            </a>
                    </div>
                </div>
                
            </motion.div>
            
        </section>

    )
}

export default Contact;